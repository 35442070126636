import React from "react";
import "./photogallery.css";

export default function PhotoGallery() {

    return (
        <div className="photo-gallery">
            <h1>Photo Gallery coming soon!</h1>
        </div>
    )
}