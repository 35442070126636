import React from "react";
import { Link, NavLink } from "react-router-dom";

import "./home.css";
import ibroFlyer from "../components/img/ibro-flyer.png"

import HeaderImg from "./img/browncounty.jpg";

const Home = () => {
  return (
    <div>
      {/* HEADER IMG */}
      <div className="headerImg">
        <img src={HeaderImg} alt="" />
      </div>
      
      <div id="hp">
        <div className="hp-container">
          <div className="hp-content">
            <span className="hp-topper">Indiana's 2nd Annual Bigfoot Conference</span>
            <h2 className="hp-title">Indiana Bigfoot Conference</h2>
          </div>

          <div className="hp-text-container">
            <h2>September 26th - September 27th, 2025</h2>
            <h2>The Seasons Lodge, Nashville, Indiana</h2>
            <NavLink className="tickets-btn" to="/tickets">Buy Tickets!</NavLink>
            <h3>Join us for the 2nd Annual Bigfoot Conference, taking place September 26th–27th! Immerse yourself in firsthand experiences, shop from local artists, and connect with fellow enthusiasts.</h3>
          </div>

          <div className="schedule-container">
            <h1>Schedule
              <h3>Coming soon...</h3>
            </h1>
          </div>

          <div className="flyer-container">
            {/* <picture className="con-flyer">
              <img 
              loading="lazy"
              decoding="async"
              srcSet={conFlyer}
              alt="Indy Bigfoot Con Flyer"
              aria-hidden="true"
              />
            </picture> */}

            <div className="host-flyer">
              <h2>Special Thanks to IBRO for Hosting Indy Bigfoot Con</h2>
              <h2 id="site-link" ><a href="https://ibroindiana.com/">https://ibroindiana.com/</a></h2>
              <picture className="ibro-flyer">
              <img 
              loading="lazy"
              decoding="async"
              srcSet={ibroFlyer}
              alt="IBRO Flyer"
              aria-hidden="true"
              />
            </picture>
            </div>

            <div className="vendor-list">
              <h2>Vendor List</h2>
              <p>Coming soon</p>
            </div>
          </div>

          <div className="dev-team-text-container">
            <h3>Website created by Alyssa Wiser</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
