import React, { useState } from "react";
import "./vendorSignup.css";
import { Button } from "react-bootstrap";
import NavLink from "react-bootstrap";

// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete for accessibility, google loves a11y for ranking.

const VendorRequestForm = () => {
  const [formType, setFormType] = useState(null)
  const [agreementForm, setAgreementForm] = useState({ checkbox: false, checkbox2: false })
  const [applicationForm, setApplicationForm] = useState({ name: "", email: "", phone: "" })

  const handleFormSubmit = (type) => {
    let emailBody;

    if (type === "agreement") {
      emailBody = `Agreement Form Submission:\n\nCheckbox 1: ${agreementForm.checkbox1 ? "Checked" : "Unchecked"}\nCheckbox 2: ${agreementForm.checkbox2 ? "Checked" : "Unchecked"}`;
    } else if (type === "application") {
      emailBody = `Application Form Submission:\n\nName: ${applicationForm.name}\nEmail: ${applicationForm.email}\nPhone: ${applicationForm.phone}`;
    }

    // Simulate email submission
    console.log(`Sending email with content:\n${emailBody}`);

    // Example integration with an email service like EmailJS or backend API call
    alert(`Form submitted! Check console for details.`);

  }
 
  return (
    <section id="rsvp">
      <div className="rsvp-container">
        <div className="rsvp-content">
          <h2 className="rsvp-title">
            Vendor applications are currently closed. Thank you for applying!
          </h2>
        </div>
        <div className="disclaimer">
        Our vendor application is currently closed.
          <br />
        </div>
      </div>

      <div>
        <div className="vendor-desc" style={{ textAlign: "center", padding: "20px", color:"#e5dece" }}>
          <p>*After the Application and Agreement submission is recieved, IBRO will notify you if you are seleceted, then you will make a payment.</p>
          <p>You must submit both the Application form and the Agreement form to be considered.</p>

          {/* <div className="vendor-btn">
            <button id="app-btn" onClick={() => setFormType("application")}>Application Form</button>
            <button id="agree-btn" onClick={() => setFormType("agreement")}>Agreement Form</button>
          </div> */}

          {/* {formType === "agreement" && (
            <div className="agree-container" style={{ marginTop: "20px" }}>
              <h2>Agreement Form</h2>
              <label>
                <input
                type="checkbox"
                checked={agreementForm.checkbox1}
                onChange={(e) => setAgreementForm({ ...agreementForm, checkbox1: e.target.checked })}
                />
                Checkbox 1
                </label>
                <br />

                <label>
                  <input
                  type="checkbox"
                  checked={agreementForm.checkbox2}
                  onChange={(e) => setAgreementForm({ ...agreementForm, checkbox2: e.target.checked })}
                  />
                  Checkbox 2
                </label>
                <br />

                <button onClick={() => handleFormSubmit("agreement")}>Submit Agreement</button>
                </div>
          )}
          
          {formType === "application" && (
            <div className="app-container" style={{ marginTop: "20px" }}>
              <h2>Application Form</h2>
              <label>
                Name:
                <input
                type="text"
                value={applicationForm.name}
                onChange={(e) => setApplicationForm({ ...applicationForm, name: e.target.value })}
                />
              </label>
              <br />

              <label>
                 Email:
                 <input
                 type="email"
                 value={applicationForm.email}
                 onChange={(e) => setApplicationForm({ ...applicationForm, email: e.target.value })}
                />
              </label>
              <br />

              <label>
               Phone:
               <input
               type="text"
               value={applicationForm.phone}
               onChange={(e) => setApplicationForm({ ...applicationForm, phone: e.target.value })}
              />
              </label>
              <br />

              <button onClick={() => handleFormSubmit("application")}>Submit Application Form</button>
            </div>
          )} */}

        </div>
      </div>
    </section>
  );
};

export default VendorRequestForm;
